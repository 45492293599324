html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f2f2f2;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
}
.MuiStepConnector-vertical {
  padding: 0 !important;
  margin: auto !important;
}
.MuiPaper-root.MuiStepper-root.MuiStepper-vertical {
  padding: 0;
  align-content: center;
  align-items: center;
}
.MuiStepper-vertical .MuiStepLabel-iconContainer {
  padding: 0 !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
